import { defineStore } from 'pinia'
import { api } from '@/plugins/api'

export const useSearchStore = defineStore('search', {
  state: () => ({
    params: {
      serviceTemplateId: -1 as number,
      serviceTemplateTitleFr: ''
    },
    isSearchMode: true as boolean,
    loading: false as boolean,
    moreResultsLoading: false as boolean,
    hasMoreResultsToLoad: true as boolean,
    searchData: undefined as any,
    results: [] as any[],
    resultsPage: -1 as number,
    searchCalled: false,
    displayResultsBeyond50km: false,
    displayedResults: [] as any[]
  }),
  getters: {
    is50kmWarningMessageIsDisplayed: (state) => {
      return (
        state.results[0]?.data.distance > 50 && !state.loading && !state.displayResultsBeyond50km
      )
    },
    isThereFilteredResults: (state) => state.displayedResults.length > 0,
    getDisplayedResultsCount: (state) => state.displayedResults.length
  },
  actions: {
    toggleResultsBeyond50km() {
      this.displayResultsBeyond50km = !this.displayResultsBeyond50km
    },
    areThereSearchResults() {
      return this.results.length > 0
    },
    async search() {
      return await api.avails.getForServiceForGeolocalisation(this.searchData)
    },
    resetSearchCalled() {
      this.searchCalled = false;
    }
  }
})
